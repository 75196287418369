@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Circular Std";
	src: url("/assets/fonts/CircularStd-Book.eot");
	src: url("/assets/fonts/CircularStd-Book.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/CircularStd-Book.woff2") format("woff2"),
		url("/assets/fonts/CircularStd-Book.woff") format("woff"),
		url("/assets/fonts/CircularStd-Book.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std";
	src: url("/assets/fonts/CircularStd-BookItalic.eot");
	src: url("/assets/fonts/CircularStd-BookItalic.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/CircularStd-BookItalic.woff2") format("woff2"),
		url("/assets/fonts/CircularStd-BookItalic.woff") format("woff"),
		url("/assets/fonts/CircularStd-BookItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std";
	src: url("/assets/fonts/CircularStd-MediumItalic.eot");
	src: url("/assets/fonts/CircularStd-MediumItalic.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/CircularStd-MediumItalic.woff2") format("woff2"),
		url("/assets/fonts/CircularStd-MediumItalic.woff") format("woff"),
		url("/assets/fonts/CircularStd-MediumItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std";
	src: url("/assets/fonts/CircularStd-Medium.eot");
	src: url("/assets/fonts/CircularStd-Medium.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/CircularStd-Medium.woff2") format("woff2"),
		url("/assets/fonts/CircularStd-Medium.woff") format("woff"),
		url("/assets/fonts/CircularStd-Medium.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std";
	src: url("/assets/fonts/CircularStd-Bold.eot");
	src: url("/assets/fonts/CircularStd-Bold.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/CircularStd-Bold.woff2") format("woff2"),
		url("/assets/fonts/CircularStd-Bold.woff") format("woff"),
		url("/assets/fonts/CircularStd-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std";
	src: url("/assets/fonts/CircularStd-BoldItalic.eot");
	src: url("/assets/fonts/CircularStd-BoldItalic.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/CircularStd-BoldItalic.woff2") format("woff2"),
		url("/assets/fonts/CircularStd-BoldItalic.woff") format("woff"),
		url("/assets/fonts/CircularStd-BoldItalic.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std";
	src: url("/assets/fonts/CircularStd-Black.eot");
	src: url("/assets/fonts/CircularStd-Black.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/CircularStd-Black.woff2") format("woff2"),
		url("/assets/fonts/CircularStd-Black.woff") format("woff"),
		url("/assets/fonts/CircularStd-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std";
	src: url("/assets/fonts/CircularStd-BlackItalic.eot");
	src: url("/assets/fonts/CircularStd-BlackItalic.eot?#iefix")
			format("embedded-opentype"),
		url("/assets/fonts/CircularStd-BlackItalic.woff2") format("woff2"),
		url("/assets/fonts/CircularStd-BlackItalic.woff") format("woff"),
		url("/assets/fonts/CircularStd-BlackItalic.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

html,
body {
	padding: 0;
	margin: 0;
	overscroll-behavior-y: none;

	color: #333;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Circular Std", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: dark) {
	input {
		color: #333333;
	}
}

.alert-background-enter {
	opacity: 0;
	background-color: rgba(0, 0, 0, 0);
}

.alert-background-enter-active {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.2);
	transition: all 300ms;
}

.alert-background-exit {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.2);
}

.alert-background-exit-active {
	opacity: 0;
	background-color: rgba(0, 0, 0, 0);
	transition: all 300ms;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
	position: relative;
	display: flex;
	flex: 1;
}
.rdtPicker {
	display: none;
	position: absolute;
	padding: 15px;

	top: 48px;
	left: 0;
	right: 0;

	z-index: 99999 !important;
	background: #fff;

	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.16);
	border-radius: 8px;

	border: 0px solid #f9f9f9;
}

.rdt > input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	flex: 1;
	margin-top: 5px;

	padding: 15px 40px 15px 10px;
	border: 1px solid #efefef;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

	border-radius: 8px;

	color: #141517;
	font-size: 14px;

	background-image: url("/assets/images/calendar.svg");
	background-repeat: no-repeat;
	background-position: 95% 60%;
	background-size: 22px 22px;
}

.rdt > input:focus {
	outline: 0;
	border: 1px solid #c4c4c4;
}

.rdt > input::placeholder,
::-webkit-input-placeholder {
	color: gray;
}

.rdt > input:-ms-input-placeholder {
	color: gray;
}

.rdtOpen .rdtPicker {
	display: block;
}
.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker .rdtTimeToggle {
	text-align: center;
}

.rdtPicker table {
	width: 100%;
	margin: 0;
}
.rdtPicker td,
.rdtPicker th {
	text-align: center;
	height: 28px;
}
.rdtPicker td {
	cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
	background: #eeeeee;
	cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
	color: #999999;
}
.rdtPicker td.rdtToday {
	position: relative;
}
.rdtPicker td.rdtToday:before {
	content: "";
	display: inline-block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #428bca;
	border-top-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	bottom: 4px;
	right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
	background-color: #6867ff;
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
	border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
	color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker th {
	border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
	cursor: default;
}
.rdtPicker th.rdtSwitch {
	width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
	font-size: 21px;
	vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
	cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
	background: #eeeeee;
}

.rdtPicker tfoot {
	border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}
.rdtPicker button:hover {
	background-color: #eee;
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}

td.rdtMonth,
td.rdtYear {
	height: 50px;
	width: 25%;
	cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
	background: #eee;
}

.rdtCounters {
	display: inline-block;
}

.rdtCounters > div {
	float: left;
}

.rdtCounter {
	height: 100px;
}

.rdtCounter {
	width: 40px;
}

.rdtCounterSeparator {
	line-height: 100px;
}

.rdtCounter .rdtBtn {
	height: 40%;
	line-height: 40px;
	cursor: pointer;
	display: block;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}
.rdtCounter .rdtBtn:hover {
	background: #eee;
}
.rdtCounter .rdtCount {
	height: 20%;
	font-size: 1.2em;
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}

.rdtMilli input {
	width: 100%;
	font-size: 1.2em;
	margin-top: 37px;
}

.rdtTime td {
	cursor: default;
}

.scrollContainer::-webkit-scrollbar {
	display: none;
}

.scrollContainer {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
